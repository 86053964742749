<template>
  <v-card flat class="pr-3">
    <v-row>
      <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12">
        <v-card outlined class="rounded-lg pa-3">
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Nilai Prestasi Kerja Tahun {{ pastYear | dateYear }}
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            {{ kinerjaSummary.nilai_tahunan }}
          </p>
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Nilai Capaian Kerja Bulan {{ currentDate | dateMonthDisplay }} Tahun
            {{ currentDate | dateYear }}
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            {{ kinerjaSummary.nilai_bulanan }}
          </p>
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Tunjangan Kinerja
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            Rp. {{ kinerjaSummary.tunjangan_kinerja | currency }}
          </p>
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Tunjangan Kinerja Penuh
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            Rp. {{ kinerjaSummary.tunjangan_kinerja_full | currency }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" xl="7" lg="7" md="7" sm="7" xs="12">
        <v-card outlined class="rounded-lg pa-3">
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Besar Tunjangan Kinerja Dari Komponen Capaian Kerja
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            Rp. {{ kinerjaSummary.tunjangan_capaian_kinerja | currency }}
          </p>
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Besar Tunjangan Kinerja Dari Komponen Capaian Kehadiran
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            Rp. {{ kinerjaSummary.tunjangan_capaian_kehadiran | currency }}
          </p>
          <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
            Besar Tunjangan Kinerja Yang Dibayarkan
          </p>
          <p class="mb-2 subtitle-2 font-weight-regular headline-color">
            Rp. {{ kinerjaSummary.tunjangan_kinerja_dibayarkan | currency }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="rounded-lg">
          <v-row class="px-3">
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="12"
              xs="12"
              style="border-right: 1px solid #E0E0E0"
            >
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Tidak Hadir
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.tidak_hadir }}
              </p>
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Total Denda
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.total_denda }}
              </p>
            </v-col>
            <v-col
              cols="6"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              style="border-right: 1px solid #E0E0E0"
            >
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Total Waktu Kurang Kehadiran
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.total_waktu_kurang_hadir }}
              </p>
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Total Kurang Waktu Keberadaan
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.total_waktu_kurang_keberadaan }}
              </p>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Akumulasi Kurang 7,5 Jam Atas Kehadiran (X 3%)
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.akumulasi_kurang_hadir }}
              </p>
              <p class="ma-0 subtitle-2 font-weight-regular subtitle-color">
                Akumulasi Kurang 7,5 Jam Atas Keberadaan (X 3%)
              </p>
              <p class="mb-2 subtitle-2 font-weight-regular headline-color">
                {{ kehadiranSummary.akumulasi_kurang_keberadaan }}
              </p>
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions class="px-5">
            <v-spacer />
            <p class="ma-0 subtitle-2 font-weight-regular headline-color">
              Total Denda/Potongan: {{ kehadiranSummary.total_denda_potongan }}
            </p>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapSummaryRootField } from "@/store/helpers";
export default {
  props: {
    currentDate: { type: String, required: false }
  },
  computed: {
    ...mapSummaryRootField({
      kinerjaSummary: "kinerja",
      kehadiranSummary: "kehadiran"
    }),
    pastYear() {
      return this.$moment().subtract(1, "year");
    }
  }
};
</script>
